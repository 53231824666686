import Layout from "../components/layout"
import Seo from "../components/seo"
import LandingPageContainer from "../components/landing-page/LandingPageContainer"
import * as React from "react"

const PrivacyPolicyPage = () => (
  <Layout>
    <Seo title="Home" />
    <div className="privacy-policy-container">
      <h1>PRIVACY POLICY</h1>
      <h4>Last updated at: February 16, 2022</h4>
      <p>
        Thank you for choosing to be part of our community at Multicaret Digital
        Services Inc. ("Company," "we," "us," or "our"). We are committed to
        protecting your personal information and your right to privacy. If you
        have any questions or concerns about this privacy notice or our
        practices with regard to your personal information, please contact us at
        info@multicaret.com.
      </p>
      <p>
        This privacy notice describes how we might use your information if you:
      </p>
      <ul>
        <li>Download and use our mobile application - Dashtronaut</li>
        <li>
          Engage with us in other related ways - including any sales, marketing,
          or events
        </li>
      </ul>
      <p>In this privacy notice, if we refer to:</p>
      <ul>
        <li>
          "App," we are referring to any application of ours that references or
          links to this policy, including any listed above
        </li>
        <li>
          "Services," we are referring to our App, and other related services,
          including any sales, marketing, or events
        </li>
      </ul>
      <p>
        The purpose of this privacy notice is to explain to you in the clearest
        way possible what information we collect, how we use it, and what rights
        you have in relation to it. If there are any terms in this privacy
        notice that you do not agree with, please discontinue use of our
        Services immediately.
      </p>
      <p>
        <strong>
          Please read this privacy notice carefully, as it will help you
          understand what we do with the information that we collect.
        </strong>
      </p>
      <p />
      <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
      <p>We do not collect any personal information</p>
      <h2>2. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
      <p>
        In Short: In some regions, such as the European Economic Area (EA) and
        United Kingdom (UK), you have rights that allow you greater access to
        and control over your personal information. You may review, change, or
        terminate your account at any time.
      </p>
      <p>
        In some regions (like the EA and UK), you have certain rights under
        applicable data protection laws. These may include the right (i) to
        request access and obtain a copy of your personal information, (i) to
        request rectification or erasure; (ili) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information. To make such a request, please
        use the contact details provided below. We will consider and act upon
        any request in accordance with applicable data protection laws. If we
        are relying on your consent to process your personal information, you
        have the right to withdraw your consent at any time. Please note however
        that this will not affect the lawfulness of the processing before its
        withdrawal, nor will it affect the processing of your personal
        information conducted in reliance on lawful processing grounds other
        than consent.
      </p>
      <p>
        If you are a resident in the EA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your local data protection supervisory authority. You can
        find their contact details
        <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
          here
        </a>
        . If you are a resident in Switzerland, the contact details for the data
        protection authorities are available
        <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">here</a>
      </p>
      <h2>3. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
      <p>
        In Short: Yes, we will update this notice as necessary to stay compliant
        with relevant laws.
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated "Revised" date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information
      </p>
      <h2>4. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
      <p>
        If you have questions or comments about this notice, you may email us at
        <a href="mailto:info@multicaret.com">info@multicaret.com</a>
        or by post to:
      </p>
      <p>
        Multicaret Dijital Hizmetlerii AŞ.
        <br />
        Merkez Mah. Kagithane Cd.
        <br />
        Y ofis No: 5/22
        <br />
        Kagithane, Istanbul 34406
        <br />
        Turkey
      </p>
    </div>
  </Layout>
)

export default PrivacyPolicyPage
